import classNames from 'classnames';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory, Link } from 'react-router-dom';

import ProductCard from '../ProductCard/ProductCard';
import Button, { SECONDARY } from '../Button/Button';
import DrawerToMenu, { DrawerContent, MenuContent } from '../DrawerToMenu/DrawerToMenu';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as EmptyIcon } from '../../images/empty_wishlist.svg';
import { ReactComponent as ArrowLeftIcon } from '../../images/icons/arrowLeft.svg';

import styles from './WishlistPopup.module.scss';
import { useSelector } from 'react-redux';
import { getPagePath, getPopupName, getPopupOnPagePath, isPopupOpened } from '../../utils/appRoutes';
import { ARD_POPUP_PROFILE, ARD_POPUP_PROFILE_WISHLIST, ARD_PROFILE_WISHLIST } from '../../utils/appRoutes.definitions';
import useWishlist from '../../utils/useWishlist';
import { isMPGTMEnabled } from '../../utils/isGTMEnabled';
import constructURL from '../../utils/constructURL';

const WishlistContents = ({ config, wishlist, slicedProductCards, profile, push, closePopup }) => {
  return (
    <div className={classNames(styles.wishlistPopup)}>
      <header className={styles.menuHeader}>
        <FormattedMessage id="common.goBack" defaultMessage="Go back">
          {() => (
            <button
              className={styles.backIcon}
              onClick={() => push({ ...location, hash: `#${config.cmsSlugs.profile}` })}
            >
              <ArrowLeftIcon />
            </button>
          )}
        </FormattedMessage>
        <h3>
          <FormattedMessage id="profile.wishlist" defaultMessage="wishlist" />
        </h3>
        <FormattedMessage id="common.menu.closeMenu" defaultMessage="Close">
          {(label) => (
            <button className={styles.closeIcon} aria-label={label} onClick={closePopup}>
              <CloseIcon />
            </button>
          )}
        </FormattedMessage>
      </header>
      {!wishlist?.length && (
        <div className={styles.empty}>
          <EmptyIcon />
          <span className={styles.emptyWishlistNotice}>
            <FormattedMessage
              id="emptyState.wishlist.add"
              defaultMessage="Add the product to the wishlist by clicking on heart and it will appear here"
            />
          </span>
          <Button variation={SECONDARY} classList={{ root: styles.emptyButton }} onClick={closePopup}>
            <FormattedMessage id="emptyState.continueShopping" defaultMessage="Continue shopping" />
          </Button>
        </div>
      )}
      {wishlist && wishlist.length > 0 && <div className={styles.productsList}>{slicedProductCards}</div>}
      {profile && wishlist && wishlist.length > 2 && (
        <Link className={styles.callToActionBtn} to={getPagePath(ARD_PROFILE_WISHLIST)}>
          <Button variation={SECONDARY}>
            <FormattedMessage id="common.seeMore" defaultMessage="See more" />
          </Button>
        </Link>
      )}
      {!profile && wishlist && wishlist.length > 0 && (
        <Link className={styles.callToActionBtn} to={constructURL(getPopupOnPagePath(ARD_POPUP_PROFILE))}>
          <Button variation={SECONDARY}>
            <FormattedMessage id="header.signUp" defaultMessage="Log in" />
          </Button>
        </Link>
      )}
    </div>
  );
};

/***
 * WishlistPopup component
 * @param {boolean} shouldBeOpen - comes from presense of wishlist specific hashname in url
 */
const WishlistPopup = ({ shouldBeOpen }) => {
  const profile = useSelector(({ profile }) => profile);
  const { wishlistProducts } = useWishlist();
  const config = useSelector(({ config }) => config);

  const location = useLocation();
  const { push } = useHistory();

  const closePopup = () => push({ ...location, hash: ' ' });

  const slicedProductCards =
    wishlistProducts &&
    wishlistProducts.length > 0 &&
    wishlistProducts
      .slice(-4)
      .reverse()
      .map((product, index) => (
        <ProductCard
          classList={{
            root: classNames(styles.wishlistCard),
            // price: styles.productPrice,
            // productPic: styles.productPic,
            // productLink: styles.productLink,
            // shop: styles.productShop,
            // delete: styles.productDelete,
            // name: styles.productName,
          }}
          product={product}
          key={`slicedWishlistProduct${index}`}
        />
      ));

  useEffect(() => {
    //popup on desktop or fullscreen menu drawer triggered by hashname on mobile (see props)
    const popupOpened = shouldBeOpen;
    if (popupOpened && isMPGTMEnabled) window.dataLayer.push({ event: 'view_wishlist_dialog' });
  }, [shouldBeOpen]);

  return (
    <DrawerToMenu data-testid="wishlistPopup">
      <DrawerContent
        direction="RightToLeft"
        trigger={getPopupName(ARD_POPUP_PROFILE_WISHLIST)}
        onOverlayClick={closePopup}
      >
        <WishlistContents
          wishlist={wishlistProducts}
          profile={profile}
          config={config}
          slicedProductCards={slicedProductCards}
          push={push}
          closePopup={closePopup}
        />
      </DrawerContent>
      <MenuContent shouldBeOpen={shouldBeOpen} classList={{ root: styles.wishlistPopupWrapper }}>
        <WishlistContents
          wishlist={wishlistProducts}
          profile={profile}
          config={config}
          slicedProductCards={slicedProductCards}
          push={push}
          closePopup={closePopup}
        />
      </MenuContent>
    </DrawerToMenu>
  );
};

WishlistPopup.displayName = 'WishlistPopup';

export default React.memo(WishlistPopup);
